define([], function() {

  const registerHome = function() {
    const component = {};

    const _init = function() {
      component.bind();
      return component;
    };

    const _bind = function() {
      const dataLayer = window.dataLayer;
      if (dataLayer.length) {
        const loginMethod = dataLayer[0].visitorLoginMethod;

        if(loginMethod === 'Register') {
          dataLayer.push({
            event: 'customEvent',
            eventData: {
              eventCategory: 'login',
              eventAction: 'account created',
              eventLabel: 'Form',
              eventPage: ''
            }
          });
          dataLayer.push({
            event: 'sign up',
            method: "form",
            user: {
              user_id: window.dataLayer[0].visitorEmailAddress,
            },
            visitorLoginState: window.dataLayer[0].visitorLoginState
          });
        } else if(loginMethod === 'facebook') {
          dataLayer.push({
            event: 'customEvent',
            eventData: {
              eventCategory: 'login',
              eventAction: 'account created',
              eventLabel: 'Facebook',
              eventPage: ''
            }
          });
          dataLayer.push({
            event: 'sign up',
            method: "facebook",
            user: {
              user_id: window.dataLayer[0].visitorEmailAddress,
            },
            visitorLoginState: window.dataLayer[0].visitorLoginState
          });
        } else if(loginMethod === 'google') {
          dataLayer.push({
            event: 'customEvent',
            eventData: {
              eventCategory: 'login',
              eventAction: 'account created',
              eventLabel: 'Google',
              eventPage: ''
            }
          });
          dataLayer.push({
            event: 'sign up',
            method: "google",
            user: {
              user_id: window.dataLayer[0].visitorEmailAddress,
            },
            visitorLoginState: window.dataLayer[0].visitorLoginState
          });
        }
      }
    };
    component.init = _init;
    component.bind = _bind;

    return component;
  };

  return registerHome;
});
